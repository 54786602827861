<template>
  <div id="nofind">
    <img src="../assets/img/404.png" />
    <p>抱歉，没有找到页面</p>
    <el-button type="primary" @click="$router.push('/')" color="#0072EF">返回首页</el-button>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss'>
  #nofind {
    width: 1200px;
    padding: 80px 0;
    margin: 50px auto 0;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 10px;

    & > img {
      display: block;
      width: 338px;
      height: 143px;
      margin: 0 auto 40px;
    }

    & > p {
      text-align: center;
      font-size: 20px;
      color: #000000;
      line-height: 28px;
      margin-bottom: 30px;
    }

    .el-button {
      width: 86px;
      display: block;
      margin: 0 auto;
    }
  }
</style>